import { ThemeProvider, styled } from "baseui";
import type { Theme } from "baseui/theme";
import { merge } from "lodash";
import type { ReactNode } from "react";
import React from "react";
import type { StyleObject } from "styletron-react";
import { EditLightTheme } from "../EditTheme";
import { CloseIcon } from "../icons/CloseIcon";
import { DocsInfoIcon } from "../icons/DocsInfoIcon";
import { FailureCircleIcon } from "../icons/FailureCircleIcon";
import { SuccessCircleIcon } from "../icons/SuccessCircleIcon";
import { WarningIcon } from "../icons/WarningIcon";
import { expandBaseWebBorder, expandBorderRadii, expandPadding } from "../utils";
import type { Overrides } from "../utils/overrides";
import { useOverrides } from "../utils/overrides";
import { Column } from "./Column";
import { Row } from "./Row";
import { IconButton } from "./button/IconButton";

export enum BannerKind {
  neutral,
  info,
  positive,
  warning,
  negative,
}

const getStylesForKind = <T extends Theme>($theme: T, kind?: BannerKind) => {
  switch (kind) {
    case BannerKind.positive:
      return {
        ...expandBaseWebBorder({
          ...$theme.borders.border100,
          borderColor: $theme.colors.borderPositive,
        }),
        backgroundColor: $theme.colors.backgroundPositive,
        color: $theme.colors.contentPositive,
      };
    case BannerKind.warning:
      return {
        ...expandBaseWebBorder({
          ...$theme.borders.border100,
          borderColor: $theme.colors.warning500,
        }),
        backgroundColor: $theme.colors.warning50,
        color: $theme.colors.warning700,
      };
    case BannerKind.negative:
      return {
        ...expandBaseWebBorder({
          ...$theme.borders.border100,
          borderColor: $theme.colors.borderNegative,
        }),
        backgroundColor: $theme.colors.backgroundNegative,
        color: $theme.colors.contentNegative,
      };
    case BannerKind.info:
      return {
        ...expandBaseWebBorder({
          ...$theme.borders.border100,
          borderColor: $theme.colors.borderInfo,
        }),
        backgroundColor: $theme.colors.backgroundInfo,
        color: $theme.colors.contentInfo,
      };
    default:
      return {
        ...expandBaseWebBorder({
          ...$theme.borders.border100,
          borderColor: $theme.colors.primary200,
        }),
        backgroundColor: $theme.colors.primary50,
        color: $theme.colors.contentPrimary,
      };
  }
};

export const getIconForKind = <T extends Theme>($theme: T, kind?: BannerKind) => {
  switch (kind) {
    case BannerKind.info:
      return <DocsInfoIcon />;
    case BannerKind.positive:
      return <SuccessCircleIcon color={$theme.colors.positive500} />;
    case BannerKind.warning:
      return <WarningIcon color={$theme.colors.warning500} />;
    case BannerKind.negative:
      return <FailureCircleIcon color={$theme.colors.negative500} />;
    default:
      return null;
  }
};

const baseThemeOverrides = { typography: { contentLink: { textDecoration: "underline" } } };

const BaseContentTheme = merge({}, EditLightTheme, baseThemeOverrides);
const InfoContentTheme = merge({}, EditLightTheme, baseThemeOverrides, {
  colors: {
    content: EditLightTheme.colors.contentInfo,
    contentLinkText: EditLightTheme.colors.blue700,
    contentLinkHover: EditLightTheme.colors.blue600,
    contentLinkActive: EditLightTheme.colors.blue500,
  },
});

const WarningContentTheme = merge({}, EditLightTheme, baseThemeOverrides, {
  colors: {
    color: EditLightTheme.colors.contentWarning,
    contentLinkText: EditLightTheme.colors.contentWarning,
    contentLinkHover: EditLightTheme.colors.yellow600,
    contentLinkActive: EditLightTheme.colors.yellow500,
  },
});

const NegativeContentTheme = merge({}, EditLightTheme, baseThemeOverrides, {
  colors: {
    color: EditLightTheme.colors.red700,
    contentLinkText: EditLightTheme.colors.red700,
    contentLinkHover: EditLightTheme.colors.red600,
    contentLinkActive: EditLightTheme.colors.red500,
  },
});

const PositiveContentTheme = merge({}, EditLightTheme, baseThemeOverrides, {
  colors: {
    color: EditLightTheme.colors.contentPositive,
    contentLinkText: EditLightTheme.colors.contentPositive,
    contentLinkHover: EditLightTheme.colors.green600,
    contentLinkActive: EditLightTheme.colors.green500,
  },
});

const themeForKind = (kind?: BannerKind) => {
  switch (kind) {
    case BannerKind.info:
      return InfoContentTheme;
    case BannerKind.positive:
      return PositiveContentTheme;
    case BannerKind.warning:
      return WarningContentTheme;
    case BannerKind.negative:
      return NegativeContentTheme;
    default:
      return BaseContentTheme;
  }
};

export const BannerTitle = styled("div", ({ $theme }) => ({
  ...$theme.typography.subHeading,
  marginBottom: $theme.sizing.scale300,
}));

export interface BannerProps {
  children?: ReactNode;
  title?: ReactNode;
  kind?: BannerKind;
  $style?: StyleObject;
  noIcon?: boolean;
  onClose?: () => void;
  primaryAction?: ReactNode;
  footer?: ReactNode;
  overrides?: Overrides<{
    Title: typeof BannerTitle;
    CloseButton: typeof IconButton;
  }>;
}

export const Banner = (props: BannerProps) => {
  const { Title, CloseButton } = useOverrides(
    {
      Title: BannerTitle,
      CloseButton: IconButton,
    },
    props.overrides
  );

  const $theme = themeForKind(props.kind);
  const icon = getIconForKind($theme, props.kind);

  const multiline = props.title || props.footer;

  const style = merge(
    {
      ...$theme.typography.LabelSmall,
      ...expandBorderRadii($theme.borders.surfaceBorderRadius),
      ...expandPadding(multiline ? $theme.sizing.scale550 : $theme.sizing.scale300),
      ...getStylesForKind($theme, props.kind),
      justifyContent: "center",
      position: "relative",
    },
    props.$style
  );

  return (
    <ThemeProvider theme={$theme}>
      <Column $style={style}>
        <Row $style={{ alignItems: multiline ? "start" : "center" }}>
          {icon && !props.noIcon && (
            <Column $style={{ paddingTop: $theme.sizing.scale0, paddingRight: $theme.sizing.scale300 }}>{icon}</Column>
          )}
          <Column $style={{ flex: 1 }}>
            {props.title && <Title>{props.title}</Title>}
            <div>{props.children}</div>
            {props.footer && <Row $style={{ marginTop: $theme.sizing.scale400 }}>{props.footer}</Row>}
          </Column>
          {props.primaryAction && <Row $style={{ alignSelf: "stretch" }}>{props.primaryAction}</Row>}
          {!!props.onClose && (
            <>
              <CloseButton
                onClick={props.onClose}
                label="Close"
                $style={{
                  position: "relative",
                  marginLeft: $theme.sizing.scale400,
                }}
              >
                <CloseIcon />
              </CloseButton>
            </>
          )}
        </Row>
      </Column>
    </ThemeProvider>
  );
};
